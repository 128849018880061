import React from 'react'
import slugify from 'slugify'
import Layout from '../../layout'
import { Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import Fade from 'react-reveal'
import * as styles from './team.module.scss'

export default ({ pageContext: { name, image, title, bio }, location }) => {
  // console.log(bio)
  return (
    <Layout location={location} title={name} description={bio} image={image} author='' metaTags={[]} slug={slugify(name).toLowerCase()} components={[]}>
      <section className={styles.section}>
        <Fade left duration={700} delay={400} distance='50px'>
          <Link to='/about#team' className={styles.back}>
            <span><img src='/arrow-left-dark.svg' className={styles.arrow} /></span>
            Back to team
          </Link>
        </Fade>
        <div className={styles.left}>
          <Fade left duration={700} delay={400} distance='50px'>
            <img src={image} className={styles.image} />
          </Fade>
        </div>
        <div className={styles.right}>
          <Fade right duration={700} delay={400} distance='50px'>
            <h2 className={styles.name}>{name}</h2>
            <h4 className={styles.title}>{title}</h4>
            <ReactMarkdown className={styles.bio}>{bio}</ReactMarkdown>
          </Fade>
        </div>
      </section>
    </Layout>
  )
}
